import http from "../HttpService";

const controller = "/team/fe/";

function getUrl() {
  return `${controller}${http.GUID}`;
}

function getUrlItem(id) {
  return `${controller}${http.GUID}/${id}`;
}

export function getData() {
  return http.get(getUrl());
}

export function getItem(id) {
  return http.get(getUrlItem(id));
}
