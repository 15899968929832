import React, { useEffect, useState } from "react";
import * as InsuranceService from "../services/data/InsuranceService";

const basePath = "assets/img/pojistovny/";

async function populateData() {
  let { data: item } = await InsuranceService.getData();
  return item.insurances;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      id: 0,
      code: "",
      name: "",
      image: "",
      link: "",
      phone: "",
      email: "",
    },
  ];

  return model;
}

function setIconForInsurance(item) {
  let image = null;

  if (item && item.image) {
    image = basePath + "" + item.image;
  }
  return image;
}

function getTooltipTitle(item) {
  let title = null;
  if (item) {
    title = item.name + " (" + item.code + ")";
  }
  return title;
}

let [data, setData] = [2];
function Insurances(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
      window.$('[data-toggle="tooltip"]').tooltip();
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <div data-aos="fade-up" data-aos-delay="100">
      <ul className="list-unstyled row justify-content-center">
        {data.map((item) => (
          <li key={item.code} className="mx-3 my-3" data-toggle="tooltip" data-placement="top" title={getTooltipTitle(item)}>
            <img src={setIconForInsurance(item)} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Insurances;
