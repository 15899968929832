import React, { useEffect, useState } from "react";
import * as TeamService from "../services/data/TeamService";
import * as Helper from "../_Helper";
import ReactHtmlParser from "react-html-parser";
import "moment/locale/cs";
import Breadcrumb from "./Breadcrumb";
import * as GeneralSettingsService from "../services/data/GeneralSettingsService";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop";
import GoBack from "./../GoBack";

async function populateData(id) {
  let { data: item } = await TeamService.getItem(id);
  return item;
}

function getViewFromModel(item) {
  let model;

  model = {
    name: "",
    position: "",
    phone: "",
    email: "",
    image: "",
    imageSrc: "",
    id: 0,
  };

  return model;
}

function getImage(item) {
  if (item) {
    if (item.image === womanNoImage) {
      item.imageSrc = womanNoImageSq;
    } else if (item.image === manNoImage) {
      item.imageSrc = manNoImageSq;
    }
  }

  let renderedImage = item.imageSrc;
  if (!renderedImage) {
    renderedImage = manNoImageSq;
  }

  return renderedImage;
}

const manNoImage = "/assets/img/noimage/ic_man_no_image_256.png";
const womanNoImage = "/assets/img/noimage/ic_woman_no_image_256.png";
const manNoImageSq = "/assets/img/noimage/ic_man_no_image_256_sq.png";
const womanNoImageSq = "/assets/img/noimage/ic_woman_no_image_256_sq.png";

let [data, setData] = [2];
function BlogDetail(props) {
  const paramId = props.match.params.id;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(paramId);
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, []);

  if (!data) {
    return "";
  }

  const hasImage = !Helper.isNullOrEmpty(data.imageSrc);
  const columns = hasImage ? "col-md-6" : "col-md-12";

  const settingsData = GeneralSettingsService.getData();
  let pageTitle = "Detail novinek v ordinaci";
  if (settingsData) {
    if (settingsData.metaTitle) {
      pageTitle = `${settingsData.metaTitle} - Detail člena týmu`;
    } else {
      pageTitle = `${settingsData.logoTitlePrefix} ${settingsData.logoTitle} - Detail člena týmu`;
    }
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumb title={data.name} section="Detail člena týmu" />
      <ScrollToTop />
      <section id="teamDetail" className="blog-detail">
        <div className="container">
          <div className="row blog-item" data-aos="fade-up">
            <div className={columns}>
              <div className="blog-item-info">
                <h4>{data.name}</h4>
                <div className="date mt-2">
                  <span>{data.position}</span>
                </div>

                {(data.phone || data.email) && (
                  <div className="mt-4">
                    {data.phone && (
                      <div>
                        <i className="icofont-phone float-left mr-2 team-icon-padding"></i>
                        <a href={Helper.getPhoneLink(data.phone)}>{data.phone}</a>
                      </div>
                    )}

                    {data.email && (
                      <div>
                        <i className="icofont-ui-email float-left mr-2 team-icon-padding"></i>
                        <a href={Helper.getEmailLink(data.email)}>{data.email}</a>
                      </div>
                    )}
                  </div>
                )}

                {data.bio && (
                  <>
                    <h5 className="mt-4">Další informace</h5>
                    <div className="mt-4">{ReactHtmlParser(data.bio)}</div>
                  </>
                )}
              </div>
            </div>

            {hasImage && (
              <div className="col-md-6">
                <div className="blog-item-img">
                  <img src={getImage(data)} alt={data.name} />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col mt-2" data-aos="fade-up">
              <GoBack />
            </div>
          </div>
        </div>
      </section>
      {/* 
      <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div className="member d-flex flex-column" data-aos="fade-up" data-aos-delay={getDelay(100, index)}>
                <div className="member-img">
                  <img src={getImage(item)} alt={item.name} />
                </div>
                <div className="member-info">
                  <h4>{item.name}</h4>
                  <span>{item.position}</span>
                  {(item.phone || item.email) && (
                    <div className="mt-2">
                      {item.phone && (
                        <div>
                          <i className="icofont-phone float-left mr-2 team-icon-padding"></i>
                          <a href={Helper.getPhoneLink(item.phone)}>{item.phone}</a>
                        </div>
                      )}

                      {item.email && (
                        <div>
                          <i className="icofont-ui-email float-left mr-2 team-icon-padding"></i>
                          <a href={Helper.getEmailLink(item.email)}>{item.email}</a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              
              </div>
            </div> */}
    </>
  );
}

export default BlogDetail;
