//import data from "./appsettings.json";
export const BLOG_MAX_CHAR_ON_HOME_PAGE = 20;

export const ID_COOKIE = "cookie";
export const ID_HOME = "home";
export const ID_BLOG = "blog";
export const ID_BLOG_DETAIL = "blog_detail";
export const ID_TEAM = "team";
export const ID_TEAM_DETAIL = "team_detail";
export const ID_ABOUT = "about";
export const ID_SERVICES = "services";
export const ID_PRICING = "pricing";
export const ID_CONTACT = "contact";

export const ID_ORDINATION_HOUR = "ordination_hour";
export const ID_EXT_INFO = "ext_info";
export const ID_INSURANCES = "insurances";
export const ID_FAQ = "faq";
export const ID_GALLERY = "gallery";
export const ID_FILES = "files";

export const ID_OTHER_PAGES = "other_pages";
export const ID_URGENT_NOTICE = "urgent_notice";

export const MENU_LINK_TYPE_SECTION = 1;
export const MENU_LINK_TYPE_OTHER_PAGE = 2;
export const MENU_LINK_TYPE_URL = 3;
export const MENU_LINK_TYPE_DROPDOWN_MENU = 4;

export const MENU_TYPE_NOT_SHOW = 5;

const CUSTOMER_SEMERADOVA = "68ff82929c8c49f698645cec3cdd8ec2";
const CUSTOMER_SLAVICEK = "284b8862ccee4bbbb6c727c41ff99f9e";
const CUSTOMER_DVORAKOVA = "e6c93b60bcce4a25a5ecee7f37c1df09";
const CUSTOMER_ORLDC = "4fffb19c8f4c4fca81c84e3ac6cba4e1";
const CUSTOMER_JUKLOVA = "5d7c7925ed904d8cb2022d80c85e2d0f";
const CUSTOMER_PRAKTIK_DOMAZLICE = "92f30afd7f5c4e5bac2149c3c9b65773";
const CUSTOMER_PRUDKOVA = "10badba5422f429785c0271e916938d0";
const CUSTOMER_HERRMANNOVA = "567fa43441c64b499a244d441c7220e0";
const CUSTOMER_AMBULANTNI_SPECIALISTA = "10cf86db5f97490e823396b082ea55c9";
const CUSTOMER_DETSKA_LEKARKA = "cc832d1be4984ed6a721e3dbf1ef56d5";
const CUSTOMER_PRAKTICKY_LEKAR = "97a71f70a7494503b3ccb1537efaa13b";
const CUSTOMER_PRAKTICKA_LEKARKA = "04698f6f99ab4c33bc747f2060fceb9c";
const CUSTOMER_BITTNEROVA = "146bbce6e50d47fdb1b6e3eb6ae54e60";

const LOCAL_API_URL = "https://localhost:44394/api";
const PRODUCTION_API_URL = "https://api.webdoordinace.cz/api";

export function isProduction() {
  return process.env.NODE_ENV === "production" ? true : false;
}

const applicationInitialState = window.__INITIAL_STATE__;
const config = applicationInitialState.config;

export const GUID = isProduction() ? config.guid : CUSTOMER_BITTNEROVA;
export const API_URL = isProduction() ? config.apiUrl : PRODUCTION_API_URL;
