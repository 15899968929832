import React from "react";
import * as Const from "../src/_Const";
const DebugInfo = () => {
  if (Const.isProduction()) {
    return "";
  }
  return (
    <React.Fragment>
      <div className="d-inline d-sm-none">XS</div>
      <div className="d-none d-sm-inline d-md-none">SM</div>
      <div className="d-none d-md-inline d-lg-none">MD</div>
      <div className="d-none d-lg-inline d-xl-none">LG</div>
      <div className="d-none d-xl-inline">XL</div>
    </React.Fragment>
  );
};

export default DebugInfo;
