import React, { useEffect, useState } from "react";
import * as OurServiceService from "../services/data/OurServiceService";
import ReactHtmlParser from "react-html-parser";

async function populateData() {
  let { data: item } = await OurServiceService.getItem();
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      text: item.text,
    };
  } else {
    model = {
      id: 0,
      text: "",
    };
  }
  return model;
}

let [data, setData] = [2];
function OurService(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  return (
    <div data-aos="fade-up" data-aos-delay="100">
      {ReactHtmlParser(data.text)}
    </div>
  );
}

export default OurService;
