import React, { useEffect, useState } from "react";
import * as PriceListService from "../services/data/PriceListService";

async function populateData() {
  let { data: item } = await PriceListService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      id: 0,
      title: "",
      validFrom: "",
      items: [],
    },
  ];

  return model;
}

function renderCategory(items) {
  if (!items) {
    return null;
  }

  return items.map((item) => (
    <li key={item.title} className="col-12 mx-1 my-1 text-center">
      <div className="row">
        <div className="text-left col-8 col-md-10">{item.title}</div>
        <div className="text-right col-4 col-md-2 pl-price-text">{item.price}</div>
      </div>
    </li>
  ));
}

function renderCategoryContainer(item) {
  if (!item) {
    return null;
  }

  return (
    <div key={item.id} className="col-12 mt-4 text-center price-list">
      {/* <div className="text-item1">{item.title}</div> */}
      <h4>{item.title}</h4>
      {item.validFrom && <div className="text-item2 mb-1">{item.validFrom}</div>}
      <ul key={item.item} className="list-unstyled row justify-content-center">
        {renderCategory(item.items)}
      </ul>
    </div>
  );
}

let [data, setData] = [2];
function PriceList(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <div data-aos="fade-up" data-aos-delay="100" className="row justify-content-center mx-1 mt-n4">
      {data.map((item) => renderCategoryContainer(item))}
    </div>
  );
}

export default PriceList;
