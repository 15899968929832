import React, { useEffect, useState } from "react";
import * as SectionService from "../services/data/SectionService";
import ReactHtmlParser from "react-html-parser";

async function populateData(sectionId) {
  let { data: item } = await SectionService.getItem(sectionId);
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      id: 0,
      code: "",
      title: "",
      subtitle: "",
      text: "",
    },
  ];
  return model;
}

//let [data, setData] = [2];
function Section(props) {
  const { children, sectionId, menuSectionId, cssClass } = props;
  const [data, setData] = useState({});

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(sectionId);
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [sectionId]);

  if (!data) {
    return "";
  }
  //naklonuj children a pridej data ze sekce
  // const childrenWithExtraProp = React.Children.map(children, (child) => React.cloneElement(child, { isSectionEnabled: data.isSectionEnabled }));

  return (
    <section key={data.id} id={menuSectionId} className={cssClass}>
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>{data.title}</h2>
          {data.subtitle && <p className="text-item2 text-center mt-n1">{data.subtitle}</p>}
        </div>

        {data.text && (
          <div className="row mt-n4 mb-2" data-aos="fade-up" data-aos-delay="50">
            <div className="col-12 mx-2 my-4">{data.text && <div>{ReactHtmlParser(data.text)}</div>}</div>
          </div>
        )}

        {children}

        {/* {childrenWithExtraProp} */}
      </div>
    </section>
  );
}

export default Section;
