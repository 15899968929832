import React, { useEffect, useState } from "react";
import * as TeamService from "../services/data/TeamService";
import * as Helper from "../_Helper";
import * as MS from "../services/MenuService";
import * as Const from "../_Const";
import { Link } from "react-router-dom";

async function populateData() {
  let { data: item } = await TeamService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      name: "",
      position: "",
      phone: "",
      email: "",
      image: "",
      imageSrc: "",
      id: 0,
    },
  ];

  return model;
}

function getDelay(value, index) {
  return value * (index + 1);
}

function getImage(item) {
  if (item) {
    if (item.image === womanNoImage) {
      item.imageSrc = womanNoImageSq;
    } else if (item.image === manNoImage) {
      item.imageSrc = manNoImageSq;
    }
  }

  let renderedImage = item.imageSrc;
  if (!renderedImage) {
    renderedImage = manNoImageSq;
  }

  return renderedImage;
}

const manNoImage = "/assets/img/noimage/ic_man_no_image_256.png";
const womanNoImage = "/assets/img/noimage/ic_woman_no_image_256.png";
const manNoImageSq = "/assets/img/noimage/ic_man_no_image_256_sq.png";
const womanNoImageSq = "/assets/img/noimage/ic_woman_no_image_256_sq.png";

let [data, setData] = [2];
function Team(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <>
      <div className="row justify-content-center">
        {data
          .sort((a, b) => parseInt(a.order) - parseInt(b.order))
          .map((item, index) => (
            <div key={item.id} className="col-12 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center">
              <div className="member d-flex flex-column" data-aos="fade-up" data-aos-delay={getDelay(100, index)}>
                <div className="member-img">
                  <img src={getImage(item)} alt={item.name} />
                </div>
                <div className="member-info">
                  <h4>{item.name}</h4>
                  <span>{item.position}</span>
                  {(item.phone || item.email) && (
                    <div className="mt-2">
                      {item.phone && (
                        <div>
                          <i className="icofont-phone float-left mr-2 team-icon-padding"></i>
                          <a href={Helper.getPhoneLink(item.phone)}>{item.phone}</a>
                        </div>
                      )}

                      {item.email && (
                        <div>
                          <i className="icofont-ui-email float-left mr-2 team-icon-padding"></i>
                          <a href={Helper.getEmailLink(item.email)}>{item.email}</a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {item.bio && (
                  <div className="text-center mb-3 mt-n1 mt-auto">
                    <Link key={item.id} to={() => MS.getLinkFromMenuIdWithDetailId(Const.ID_TEAM_DETAIL, item.id)}>
                      Více informací
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default Team;
