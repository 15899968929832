import http from "../HttpService";

const controller = "/otherpage/fe/";

function getUrlItem(id) {
  return `${controller}${http.GUID}/${id}`;
}

export function getItem(id) {
  return http.get(getUrlItem(id));
}
