import React from "react";
import Breadcrumb from "./Breadcrumb";
import ScrollToTop from "./../ScrollToTop";

function CookiePageDetail(props) {
  const title = "Zásady pro používání souborů cookies";
  return (
    <>
      <Breadcrumb title={title} />
      <ScrollToTop />
      <section id="cookieDetail">
        <div class="container">
          {/* <div class="row text-center">
          <div class="col-12 web-app-brand-logo">
            <a href="/#"><img src="assets/img/_moje/logo_small_black.png" alt="" /></a>
          </div>
        </div> */}
          <div class="web-app-section-title web-app-headline text-center mt-6">
            <h2>{title}</h2>
          </div>
          <div class="row mt-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="col-12">
              <h3>Co jsou cookies?</h3>
              <p>Cookies jsou krátké textové soubory, které navštívená webová stránka odešle do prohlížeče.</p>
              <p>
                Umožňují webu zaznamenat informace o vaší návštěvě, například preferovaný jazyk a další nastavení. Příští návštěva stránek tak může být snazší a
                produktivnější.
              </p>
              <p>Soubory cookie jsou důležité.</p>
            </div>
          </div>

          <div class="row mt-4 wow fadeInUp" data-wow-delay="0.3s">
            <div class="col-12">
              <h2>Soubory cookie třetích stran</h2>

              <p>
                Tyto stránky využívají službu Google Analytics, poskytovanou společností Google, Inc. (dále jen „Google“). Služba Google Analytics používá
                souborů „cookies“, které jsou textovými soubory ukládanými do vašeho počítače, umožňující analýzu způsobu užívání této stránky jejími uživateli.
                Informace vygenerované souborem cookie o užívání stránky (včetně vaší IP adresy) budou společností Google přeneseny a uloženy na serverech ve
                Spojených státech.
              </p>

              <p>
                Google bude užívat tyto informace pro účely vyhodnocování užívání stránky a vytváření zpráv o její aktivitě, určených pro její provozovatele, a
                pro poskytování dalších služeb týkajících se činností na stránce a užívání internetu vůbec. Google může také poskytnout tyto informace třetím
                osobám, bude-li to požadováno zákonem nebo budou-li takovéto třetí osoby zpracovávat tyto informace pro Google. Google nebude spojovat vaši IP
                adresu s jakýmikoli jinými daty, která má k dispozici. Používáním této stránky souhlasíte se zpracováváním údajů o vás společností Google, a to
                způsobem a k účelu shora uvedeným.
              </p>

              <p>
                Detailní informace o Google Analytics a ochraně osobních údajů naleznete na{" "}
                <a href="https://policies.google.com/privacy?hl=cs" class="link" target="_blank">
                  https://policies.google.com/privacy?hl=cs
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CookiePageDetail;
