import http from "../HttpService";

export const DAY_ID_MONDAY = 1;
export const DAY_ID_TUESDAY = 2;
export const DAY_ID_WEDNESDAY = 3;
export const DAY_ID_THURSDAY = 4;
export const DAY_ID_FRIDAY = 5;
export const DAY_ID_SATURDAY = 6;
export const DAY_ID_SUNDAY = 7;

const controller = "/openinghour/fe/";

function getUrl() {
  return `${controller}${http.GUID}`;
}

export function getData() {
  return http.get(getUrl());
}

export function getDayById(id) {
  let day = "";
  switch (id) {
    case DAY_ID_MONDAY:
      day = "Pondělí";
      break;
    case DAY_ID_TUESDAY:
      day = "Úterý";
      break;
    case DAY_ID_WEDNESDAY:
      day = "Středa";
      break;
    case DAY_ID_THURSDAY:
      day = "Čtvrtek";
      break;
    case DAY_ID_FRIDAY:
      day = "Pátek";
      break;
    case DAY_ID_SATURDAY:
      day = "Sobota";
      break;
    case DAY_ID_SUNDAY:
      day = "Neděle";
      break;
  }
  return day;
}
