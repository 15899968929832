import http from "../HttpService";

const controller = "/generalsettings/fe/";

function getUrl() {
  return `${controller}${http.GUID}`;
}

export function loadData() {
  return http.get(getUrl());
}

export function getData() {
  return data;
}

let data = {};
export function setData(item) {
  data = item;
}
/*

function getCustomerData(guid) {
  let data = null;
  switch (guid) {
    case Const.CUSTOMER_SEMERADOVA:
      data = {
        logoTitlePrefix: "MUDr.",
        logoTitle: "J. Semerádová",
        logoIcon: "icofont-stethoscope-alt",
        heroTitle: "Vítejte na stránkách MUDr. Jany Semerádové",
        heroSubtitle: "Praktické lékařky pro dospělé",
        heroImage: "lekarka-semeradova.jpg",
        footerTitle: "MUDr. Jana Semerádová",
      };
      break;
    case Const.CUSTOMER_SLAVIK:
      data = {
        logoTitlePrefix: "MUDr.",
        logoTitle: "Jan Slavík",
        logoIcon: "icofont-medical-sign",
        heroTitle: "Vítejte na stránkách MUDr. Jana Slavíka",
        heroSubtitle: "Praktického lékaře pro dospělé",
        heroImage: "lekar-slavik2.png",
        footerTitle: "MUDr. Jan Slavík",
      };
      break;
  }
  return data;
}*/
