import React, { useEffect, useState } from "react";
import * as AboutService from "../services/data/AboutService";
import ReactHtmlParser from "react-html-parser";

async function populateData() {
  let { data: item } = await AboutService.getItem();
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      text: item.text,
    };
  } else {
    model = {
      id: 0,
      text: "",
    };
  }
  return model;
}

let [data, setData] = [2];
function About(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  return (
    <div className="row content">
      <div className="col-lg-12" data-aos="fade-up" data-aos-delay="150">
        {ReactHtmlParser(data.text)}
      </div>
    </div>
  );
}

export default About;
