import React, { useEffect, useState } from "react";
import * as UrgentNoticeService from "../services/data/UrgentNoticeService";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

async function populateData() {
  let { data: item } = await UrgentNoticeService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      id: 0,
      title: "",
      text: "",
      to: 0,
      from: 0,
    },
  ];

  return model;
}

function checkNoticeShow(item) {
  if (!item) {
    return false;
  }

  let show = false;

  const from = item.from;
  const to = item.to;
  if (from > 0 && to > 0) {
    let fromDate, toDate;

    if (from) {
      try {
        fromDate = new moment.unix(from);
      } catch (error) {}
    }

    if (to) {
      try {
        toDate = new moment.unix(to);
      } catch (error) {
        console.log(error);
      }
    }

    const now = new moment(moment().format("YYYY-MM-DD"));
    //neni nic vyplneno nebo se nepodarilo parsovat datum
    //vzdy ukazat
    if ((!fromDate || (fromDate && !fromDate.isValid())) && (!toDate || (toDate && !toDate.isValid()))) {
      show = true;
      //console.log("nothing filled");
    } else if (fromDate && fromDate.isValid() && toDate && toDate.isValid()) {
      //console.log("isBetween");

      if (now.isBetween(fromDate, toDate, null, "[]")) {
        show = true;
      }
    } else if (fromDate && fromDate.isValid() && (!toDate || (toDate && !toDate.isValid()))) {
      if (now.isSameOrAfter(fromDate)) {
        show = true;
      }
    } else if (toDate && toDate.isValid() && (!fromDate || (fromDate && !fromDate.isValid()))) {
      if (now.isSameOrBefore(toDate)) {
        show = true;
      }
    }
  } else {
    //from a to neni nastaveno - proto zobraz notice
    show = true;
  }

  return show;
}

let [data, setData] = [2];
let [show, setShow] = [2];
function UrgentNotice() {
  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [show, setShow] = useState(false);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
        setShow(checkNoticeShow(data));
      }
    }
    scopedLoadData();
  }, []);

  if (!data.id > 0) {
    return "";
  }

  return (
    <section id="urgentNotice" className="pt-0 pb-2 mt-4">
      <div className="container">
        {show && (
          <>
            <div className="card urgent-notice" data-aos="fade-up" data-aos-delay="150">
              <div className="card-body">
                <div className="title mx-4 mb-2 text-center">{ReactHtmlParser(data.title)}</div>
                <div className="text  mx-4 mt-4 mb-2">{ReactHtmlParser(data.text)}</div>
                <a className="btn-action float-right" onClick={() => setShow(false)}>
                  <span>Zavřít</span>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default UrgentNotice;
