import React from "react";
import { Link } from "react-router-dom";
import * as MS from "../services/MenuService";
import * as Const from "../_Const";
import useJquery from "react-use-jquery";
import * as GeneralSettingsService from "../services/data/GeneralSettingsService";

function backToTopHandler() {
  $("html, body").animate(
    {
      scrollTop: 0,
    },
    1000,
    "easeInOutExpo"
  );
  return false;
}
let $;
function Footer() {
  $ = useJquery();
  const year = new Date().getFullYear();
  const data = GeneralSettingsService.getData();
  const provozovatel = data.companyName ? `Provozovatel: ${data.companyName}` : "";
  const ic = data.companyIc ? `IČ: ${data.companyIc}` : "";
  const dic = data.companyDic ? `DIČ: ${data.companyDic}` : "";
  const street = data.street ? `${data.street},` : "";
  const city = data.city ? `${data.city},` : "";
  const zip = data.zip ? `${data.zip}` : "";
  const sidlo = street || city || zip ? `- sídlo: ${street} ${city} ${zip}` : "";
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-12 text-center">
              {data && data.footerTitle && (
                <div className="copyright">
                  &copy;{" "}
                  <a href="https://app.WebDoOrdinace.cz/" target="_blank">
                    {data.footerTitle}
                  </a>{" "}
                  {year}
                  {""} - <Link to={() => MS.getLinkFromMenuId(Const.ID_COOKIE)}>Informace o cookies</Link>
                </div>
              )}
              <div className="row my-2">
                <div className="col-12 court-text">
                  {provozovatel} {ic} {dic} {sidlo}
                </div>
                <div className="col-12 court-text">{data.courtText}</div>
              </div>

              <div className="credits">
                <strong>
                  Vytvořeno v systému{" "}
                  <a href="https://WebDoOrdinace.cz/" target="_blank">
                    WebDoOrdinace.cz
                  </a>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="back-to-top" onClick={() => backToTopHandler()}>
        <i className="icofont-simple-up"></i>
      </div>
    </>
  );
}

export default Footer;
