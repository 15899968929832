import React, { useEffect, useState } from "react";
import * as FaqService from "../services/data/FaqService";
import ReactHtmlParser from "react-html-parser";

async function populateData() {
  let { data: item } = await FaqService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      id: 0,
      question: "",
      answer: "",
    },
  ];

  return model;
}

let [data, setData] = [2];
function Faq(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <div data-aos="fade-up" data-aos-delay="100">
      {data.map((item) => (
        <div key={item.id} className="row faq-item d-flex align-items-stretch">
          <div className="col-lg-5">
            <i className="ri-question-line"></i>
            <h4>{ReactHtmlParser(item.question)}</h4>
          </div>
          <div className="col-lg-7">{ReactHtmlParser(item.answer)}</div>
        </div>
      ))}
    </div>
  );
}

export default Faq;
