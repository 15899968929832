import React, { useEffect, useState } from "react";
import * as OpeningHourService from "../services/data/OpeningHourService";

async function populateData() {
  let { data: item } = await OpeningHourService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = {
    openingHours: [
      {
        dayId: 0,
        isClosed: false,
        isOpen24: false,
        times: [
          {
            label: "",
            start: "",
            end: "",
          },
        ],
      },
    ],
  };
  return model;
}

function renderNonNullLabel(hasSomeRowLabel, label) {
  if (hasSomeRowLabel && !label) {
    return "\u00A0";
  } else {
    return label;
  }
}

function renderDay(day) {
  if (!day) {
    return null;
  }

  let otherThanNull = false;
  if (day.times) {
    otherThanNull = day.times.some(function (el) {
      return el !== null && el.label !== null;
    });
  }

  const timeItems = day.times ? day.times.length : 0;
  let classCol = " mt-1 mb-2 mr-0 col-12";

  if (timeItems >= 4) {
    classCol = " mt-1 mb-2 mr-0 col-12";
  } else if (timeItems >= 3) {
    classCol = " mt-1 mb-2 mr-0 col-12 col-md-4";
  } else if (timeItems >= 2) {
    classCol = " mt-1 mb-2 mr-0 col-12 col-md-4";
  }

  return (
    <React.Fragment>
      <div className="row my-2">
        <div className="col-6 col-md-2 col-lg-3">
          <span className="oh_day_text">{OpeningHourService.getDayById(day.dayId)}</span>
        </div>
        <div className="col-6 col-md-10 col-lg-9 pl-0">
          <ul className="list-unstyled row oh_day">
            {day.times &&
              day.times.map((item) => (
                <li key={item.start} className={"list-item" + classCol}>
                  <div className="row">
                    <div className="col-12 text-left">
                      <i className="icofont-clock-time icofont-1x"></i>
                      <span className="oh_time_hour_text">{!day.isOpen24 && !day.isClosed && item.start + " - " + item.end}</span>
                    </div>
                  </div>
                  <span className="oh_time_label_text text-secondary">{renderNonNullLabel(otherThanNull, item.label)}</span>
                </li>
              ))}

            {/* nema times a je zavreno nebo otevreno cely den */}
            {!day.times && (
              <li key={day.dayId} className="list-item mb-3 col-12 mt-1">
                <div className="row">
                  <div className="col-12 text-left">
                    <span className="oh_time_hour_text">
                      {day.isClosed && "Zavřeno"}
                      {day.isOpen24 && "Otevřeno 24 hodin"}
                    </span>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

function getSelectedDateClass(todayIndex, item) {
  return item.dayId === todayIndex ? " oh_selected_day" : "";
}

let [data, setData] = [2];
let todayIndex = 0;
const cols = 12;
function OpeningHour(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        todayIndex = new Date().getDay();
        setData(result);
      }
    }
    // if (isSectionEnabled) {
    scopedLoadData();
    //}
  }, [isSectionEnabled]);

  if (!data && !data.openingHours && data.openingHours.dayId === 0) {
    return "";
  }

  return (
    <div data-aos="fade-up" data-aos-delay="100">
      {data.openingHours.map((item) => (
        <div key={item.dayId} className={"row justify-content-center mx-2" + getSelectedDateClass(todayIndex, item)}>
          <div className={"col-" + cols + " text-left"}>{renderDay(item)}</div>
        </div>
      ))}
    </div>
  );
}

export default OpeningHour;
