import React, { useEffect, useState } from "react";
import * as FileService from "../services/data/FileService";

async function populateData() {
  let { data: item } = await FileService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      items: [],
    },
  ];

  return model;
}

function renderFileRow(item) {
  if (!item) {
    return null;
  }

  let title = item.fileNameOriginal;
  if (item.title) {
    title = item.title;
  }

  return (
    <li key={item.fileName} className="col-12 mx-1 my-1">
      <div className="row">
        <div className="text-left col-8 col-md-10">
          <a href={item.fileSrc} target="_blank" rel="noopener noreferrer" className="file-item">
            {title}
          </a>
        </div>
        <div className="d-flex justify-content-end col-4 col-md-2">
          <a className="file-item-i no-underline" href={item.fileSrc} target="_blank" rel="noopener noreferrer" title="Stáhnout">
            <i className="icofont-download position-relative file-item-i"></i>
          </a>
        </div>
      </div>
    </li>
  );
}

function Files(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  const [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <div data-aos="fade-up" data-aos-delay="100" className="row justify-content-center mx-1 mt-1">
      <ul className="col-12 list-unstyled row justify-content-center">{data.map((item) => renderFileRow(item))}</ul>
    </div>
  );
}

export default Files;
