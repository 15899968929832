import React from "react";
import { useHistory } from "react-router-dom";
import * as Const from "../_Const";

export let nonDbMenuList = [
  {
    id: Const.ID_HOME,
    name: "Domů",
    link: "#domu",
    typeId: Const.MENU_TYPE_NOT_SHOW,
  },
  {
    id: Const.ID_BLOG_DETAIL,
    name: "Detail blogu",
    link: "blog-detail",
    typeId: Const.MENU_TYPE_NOT_SHOW,
  },
  {
    id: Const.ID_TEAM_DETAIL,
    name: "Detail člena týmu",
    link: "team-detail",
    typeId: Const.MENU_TYPE_NOT_SHOW,
  },
  {
    id: Const.ID_OTHER_PAGES,
    name: "Ostatní stránky",
    link: "ostatni",
    typeId: Const.MENU_TYPE_NOT_SHOW,
  },
  {
    id: Const.ID_COOKIE,
    name: "Cookie",
    link: "cookie",
    typeId: Const.MENU_TYPE_NOT_SHOW,
  },
];

function getIdForMenu(menuItem) {
  if (!menuItem) {
    return null;
  }

  let menuId = "";
  switch (menuItem.linkTypeId) {
    case Const.MENU_LINK_TYPE_SECTION:
      menuId = menuItem.linkIdSection;
      break;
    case Const.MENU_LINK_TYPE_OTHER_PAGE:
      menuId = menuItem.id;
      break;
    case Const.MENU_LINK_TYPE_DROPDOWN_MENU:
      menuId = menuItem.id;
      break;
    case Const.MENU_LINK_TYPE_URL:
      menuId = menuItem.id;
      break;
  }
  //console.log(menuItem.id, menuId);
  return menuId;
}

function getItemByParentId(parentId, list) {
  let item = null;
  if (list) {
    item = list.find((x) => x.id === parentId);
  }
  return item;
}

export let menuList = [];
export function setMenuList(list) {
  const oldMenuList = [...nonDbMenuList];
  list.map(function (item) {
    const id = getIdForMenu(item);

    oldMenuList.push({
      id: id,
      name: item.title,
      link: item.link,
      sectionLink: item.sectionLink,
      order: item.order,
      linkId: item.linkId,
      linkTypeId: item.linkTypeId,
      linkIdSection: item.linkIdSection,
      linkIdOther: item.linkIdOther,
      parentId: item.parentId,
      menuId: item.id,
    });
  });
  menuList = oldMenuList;
}

export function getMenuList(parentId) {
  let menu = menuList.sort((a, b) => parseInt(a.order) - parseInt(b.order)).filter((g) => g.parentId === parentId);
  if (!menu || (menu && menu.length === 0)) {
    menu = null;
  }
  return menu;
}

export function getMenuListAll() {
  let menu = menuList.sort((a, b) => parseInt(a.order) - parseInt(b.order));
  if (!menu || (menu && menu.length === 0)) {
    menu = null;
  }
  return menu;
}

export function getMenuOtherPagesList() {
  let menu = menuList.filter((g) => g.linkTypeId === Const.MENU_LINK_TYPE_OTHER_PAGE).sort((a, b) => parseInt(a.order) - parseInt(b.order));
  if (!menu || (menu && menu.length === 0)) {
    menu = null;
  }
  return menu;
}

export function goToDetailPage(id, detailId) {
  const path = getLinkFromMenuIdWithDetailId(id, detailId);
  history.push(path);
}

export function getLinkRouteWithDetailId(id) {
  let link;
  const menuItem = getMenuIndexByID(id);
  if (menuItem) {
    link = `/${menuItem.link}/:id`;
  }
  return link;
}

export function getLinkFromMenuIdWithDetailId(id, detaildId, withoutFirstSlash = false) {
  let link;
  const menuItem = getMenuIndexByID(id);
  if (menuItem) {
    if (detaildId) {
      if (withoutFirstSlash) {
        link = `${menuItem.link}/${detaildId}`;
      } else {
        link = `/${menuItem.link}/${detaildId}`;
      }
    }
  }
  return link;
}

export function getMenuIndexByID(id) {
  if (!id) {
    return null;
  }

  const data = menuList.filter((g) => g.id === id);

  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}

export function getTitleFromMenuId(id) {
  const menuItem = getMenuIndexByID(id);
  let title = "";
  if (menuItem) {
    title = menuItem.name;
  }
  return title;
}

export function getLinkFromMenuId(id) {
  const menuItem = getMenuIndexByID(id);
  let link = "";
  if (menuItem) {
    link = "/" + menuItem.link;
  }
  return link;
}

export function getDetailLinkRoute(id, childId) {
  let link;
  const menuItem = getMenuIndexByID(id);
  if (menuItem) {
    link = `/${menuItem.link}/${childId}`;
  }

  return link;
}

export function goBack() {
  history.goBack();
}

export function goToPage(id) {
  // setAppContext({ ...appContext, activeId: id, activeSubId: null });
  const path = getLinkFromMenuId(id);
  history.push(path);
}

let history;
export function MenuService() {
  history = useHistory();

  return <div></div>;
}
