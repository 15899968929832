import React, { Suspense } from "react";
import * as Const from "../_Const";

function loadComponent(guid) {
  const component = React.lazy(() => import(`../components/customers/${guid}/HeroCustom.js`));
  return component;
}

let isComponentLoaded = false;
let Component = null;
function Hero() {
  if (!isComponentLoaded) {
    Component = loadComponent(Const.GUID);
    isComponentLoaded = true;
  }

  if (!isComponentLoaded) {
    return "";
  }
  return (
    <>
      <Suspense fallback={<div>Nahrává se...</div>}>
        <Component />
      </Suspense>
    </>
  );
}

export default Hero;
