import React, { useEffect, useState } from "react";
import * as ExtInfoService from "../services/data/ExtInfoService";

async function populateData() {
  let { data: item } = await ExtInfoService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = {
    extInfoCategory: [
      {
        id: 0,
        code: "",
        text: "",
      },
    ],
    extInfo: [
      {
        id: 0,
        extInfoCategoryId: 0,
        code: "",
        text: "",
        desc: "",
        image: "",
      },
    ],
  };

  return model;
}

function getIcon(image) {
  return (
    <div className="ic-svg mx-auto">
      <svg viewBox="0 0 62 62">
        <defs>
          <style className="cls-1 cls-2"></style>
        </defs>
        <g>
          <g>
            <circle className="cls-1" cx="31" cy="31" r="30" />
            {getImagePath(image)}
          </g>
        </g>
      </svg>
    </div>
  );
}

function getImagePath(image) {
  let path = null;
  switch (image) {
    case "360":
      path = (
        <>
          <path
            className="cls-2"
            d="M31,13.5c-8.11,0-14.69,3.29-14.69,7.35,0,3.29,4.32,6.06,10.28,7V32.6l5.88-5.88-5.88-5.87v4c-4.62-.83-7.34-2.8-7.34-4,0-1.56,4.46-4.41,11.75-4.41s11.75,2.85,11.75,4.41c0,1.07-2.14,2.77-5.87,3.71v3c5.18-1.13,8.81-3.71,8.81-6.72C45.69,16.79,39.11,13.5,31,13.5Z"
          />
          <text className="cls-3" transform="translate(20.03 45.29)">
            360°
          </text>
        </>
      );
      break;
    case "ac":
      path = (
        <>
          <path
            className="cls-2"
            d="M31,35.61H30V29a6,6,0,0,1-4,1.58,6.12,6.12,0,0,1-6-6v-1h6.61a6,6,0,0,1-1.58-4,6.11,6.11,0,0,1,6-6h1v6.6a6,6,0,0,1,4-1.57,6.12,6.12,0,0,1,6,6v1H35.45a6,6,0,0,1,1.58,4A6.11,6.11,0,0,1,31,35.61Zm1-9.92v7.78a4,4,0,0,0,0-7.78Zm-9.93-.13a4,4,0,0,0,7.78,0Zm10.06-2h7.78a4,4,0,0,0-7.78,0ZM30,15.64a4,4,0,0,0,0,7.78Z"
          />
          <text className="cls-3m" transform="translate(21.78 45.45)">
            <tspan className="cls-4">A</tspan>
            <tspan x="8.66" y="0">
              C
            </tspan>
          </text>
        </>
      );
      break;
    case "above_standard":
      path = (
        <path
          className="cls-2"
          d="M43.27,24.91h-8.6L36,18.68l0-.44a2,2,0,0,0-.6-1.44L34,15.36l-9,9a2.64,2.64,0,0,0-.81,1.92V39.91a2.74,2.74,0,0,0,2.73,2.73H39.18A2.72,2.72,0,0,0,41.69,41l4.12-9.61a2.74,2.74,0,0,0,.19-1V27.64A2.74,2.74,0,0,0,43.27,24.91Zm0,5.45-4.09,9.55H26.91V26.27l5.92-5.92-1.52,7.29h12ZM16,26.27h5.45V42.64H16Z"
        />
      );
      break;
    case "baby_carriage":
      path = (
        <path
          className="cls-2"
          d="M31.63,12.5v14h14A14,14,0,0,0,31.63,12.5ZM35.13,23V16.6a10.57,10.57,0,0,1,6.4,6.4Zm-15,5.25-1.66-3.5h-6.1v3.5h3.88S19.57,35.37,20,36a6.12,6.12,0,1,0,9,6.26h3.64a6.12,6.12,0,1,0,10.11-5.44,13.93,13.93,0,0,0,3-8.56ZM22.88,44a2.63,2.63,0,1,1,2.62-2.62A2.62,2.62,0,0,1,22.88,44Zm15.75,0a2.63,2.63,0,1,1,2.62-2.62A2.62,2.62,0,0,1,38.63,44Zm1.29-9.35-.51.65a6.56,6.56,0,0,0-.79,0,6.13,6.13,0,0,0-5.53,3.5H28.4a6.2,6.2,0,0,0-4.69-3.45l-.77-1.17c-.17-.3-.59-1.21-1.17-2.38H41.53A10.35,10.35,0,0,1,39.92,34.65Z"
        />
      );
      break;
    case "bike":
      path = (
        <path
          className="cls-2"
          d="M35.2,20.08a2.92,2.92,0,1,0-2.92-2.92A2.93,2.93,0,0,0,35.2,20.08ZM19.89,29.56a7.29,7.29,0,1,0,7.29,7.29A7.22,7.22,0,0,0,19.89,29.56Zm0,12.39a5.1,5.1,0,1,1,5.1-5.1A5.17,5.17,0,0,1,19.89,42Zm8.45-14.58,3.5-3.5L33,25a10.25,10.25,0,0,0,7.44,3.06V25.18A7.24,7.24,0,0,1,35.2,23l-2.77-2.77a3.56,3.56,0,0,0-2.34-.88,2.44,2.44,0,0,0-2,.88L24,24.31a2.89,2.89,0,0,0-.88,2,2.45,2.45,0,0,0,.88,2l4.67,4.09v7.29h2.91v-9Zm12,2.19a7.29,7.29,0,1,0,7.29,7.29A7.22,7.22,0,0,0,40.3,29.56ZM40.3,42a5.1,5.1,0,1,1,5.11-5.1A5.16,5.16,0,0,1,40.3,42Z"
        />
      );
      break;
    case "card_pay":
      path = (
        <path
          className="cls-2"
          d="M45,17H17a3.47,3.47,0,0,0-3.48,3.5l0,21A3.49,3.49,0,0,0,17,45H45a3.49,3.49,0,0,0,3.5-3.5v-21A3.49,3.49,0,0,0,45,17Zm0,24.5H17V31H45ZM45,24H17V20.5H45Z"
        />
      );
      break;
    case "elevator":
      path = (
        <>
          <path
            className="cls-2"
            d="M20.82,41.79V33.7H19.2V27.76a2.16,2.16,0,0,1,2.16-2.16H24.6a2.16,2.16,0,0,1,2.16,2.16V33.7H25.14v8.09Zm13.49,0V35.32h3.24l-2.74-8.24a2.17,2.17,0,0,0-2.05-1.48h-.13a2.16,2.16,0,0,0-2.05,1.48l-2.74,8.24h3.24v6.47ZM23,24.52a2.16,2.16,0,1,0-2.16-2.16A2.15,2.15,0,0,0,23,24.52Zm9.71,0a2.16,2.16,0,1,0-2.15-2.16A2.15,2.15,0,0,0,32.69,24.52Z"
          />
          <path className="cls-2" d="M42.76,32.74l4.37,4.37,4.37-4.37Z" />
          <path className="cls-2" d="M42.76,29.26l4.37-4.37,4.37,4.37Z" />
          <path
            className="cls-2"
            d="M36.87,15.71h-17a3.39,3.39,0,0,0-3.38,3.38V42.91a3.39,3.39,0,0,0,3.38,3.38h17a3.39,3.39,0,0,0,3.38-3.38V19.09A3.39,3.39,0,0,0,36.87,15.71Zm1.93,26.6a2.12,2.12,0,0,1-2.11,2.11H20.06A2.12,2.12,0,0,1,18,42.31V19.69a2.12,2.12,0,0,1,2.11-2.11H36.69a2.12,2.12,0,0,1,2.11,2.11Z"
          />
        </>
      );
      break;
    case "hot_drink":
      path = (
        <>
          <path
            className="cls-2"
            d="M45.9,23.27H18.7v17a6.79,6.79,0,0,0,6.8,6.8H35.7a6.79,6.79,0,0,0,6.8-6.8v-5.1h3.4a3.4,3.4,0,0,0,3.4-3.4v-5.1A3.39,3.39,0,0,0,45.9,23.27Zm-6.8,17a3.41,3.41,0,0,1-3.4,3.4H25.5a3.41,3.41,0,0,1-3.4-3.4V26.67h17Zm6.8-8.5H42.5v-5.1h3.4Z"
          />
          <path
            className="cls-2"
            d="M25.48,17a3,3,0,0,1,.79,2.59l-.13.69h2.25l.07-.5a5,5,0,0,0-1.53-4.37l-.09-.08a3,3,0,0,1-.79-2.59l.11-.7H23.94l-.08.51A5.06,5.06,0,0,0,25.4,17Z"
          />
          <path
            className="cls-2"
            d="M29.61,17a3,3,0,0,1,.79,2.59l-.13.69h2.25l.07-.5a5,5,0,0,0-1.53-4.37L31,15.36a3,3,0,0,1-.79-2.59l.1-.7H28.07l-.07.51A5.05,5.05,0,0,0,29.53,17Z"
          />
          <path
            className="cls-2"
            d="M33.82,17a3,3,0,0,1,.79,2.59l-.13.69h2.25l.07-.5a5,5,0,0,0-1.53-4.37l-.08-.08a2.94,2.94,0,0,1-.79-2.59l.1-.7H32.28l-.07.51A5,5,0,0,0,33.74,17Z"
          />
        </>
      );
      break;
    case "long_wait":
      path = (
        <>
          <text className="cls-3m" transform="translate(25.08 52.82)">
            15
          </text>
          <path
            className="cls-3"
            d="M29.33,18.32v8.33l7.09,4.2,1.28-2.13-5.87-3.48V18.32ZM46,21.65V10l-4.4,4.4A15,15,0,1,0,46,25H42.67a11.68,11.68,0,1,1-3.42-8.25l-4.92,4.91Z"
          />
        </>
      );
      break;
    case "mhd":
      path = (
        <>
          <path
            className="cls-2"
            d="M31,13.5c-8.14,0-14.74.92-14.74,7.37V39.29a5.48,5.48,0,0,0,1.85,4.09v3.28A1.84,1.84,0,0,0,20,48.5h1.84a1.85,1.85,0,0,0,1.84-1.84V44.82H38.37v1.84a1.85,1.85,0,0,0,1.84,1.84h1.84a1.84,1.84,0,0,0,1.84-1.84V43.38a5.48,5.48,0,0,0,1.85-4.09V20.87C45.74,14.42,39.14,13.5,31,13.5ZM41.43,19H20.57c1-1,3.63-1.83,10.43-1.83S40.41,18,41.43,19Zm.62,3.68v5.55H20V22.69Zm-.62,17.94-.54.5H21.11l-.54-.5A1.77,1.77,0,0,1,20,39.29V31.92h22.1v7.37A1.77,1.77,0,0,1,41.43,40.63Z"
          />
          <circle className="cls-2" cx="24.55" cy="36.53" r="2.76" />
          <circle className="cls-2" cx="37.45" cy="36.53" r="2.76" />
        </>
      );
      break;
    case "new_patients":
      path = (
        <>
          <path
            className="cls-2"
            d="M31,16.22a2.72,2.72,0,1,1-2.72,2.71A2.73,2.73,0,0,1,31,16.22m0,12.21c3.67,0,7.87,1.76,8.15,2.72v1.36H22.85V31.16c.28-1,4.48-2.73,8.15-2.73M31,13.5a5.43,5.43,0,1,0,5.43,5.43A5.43,5.43,0,0,0,31,13.5Zm0,12.22c-3.63,0-10.86,1.82-10.86,5.43v4.07H41.86V31.15C41.86,27.54,34.63,25.72,31,25.72Z"
          />
          <polygon className="cls-2" points="38.33 38.72 36.61 36.98 28.55 45.04 25.4 41.9 23.67 43.62 28.55 48.5 38.33 38.72" />
        </>
      );
      break;
    case "new_patients_on_ask":
      path = (
        <>
          <path
            className="cls-2"
            d="M31,16.43a2.68,2.68,0,1,1-2.68,2.68A2.69,2.69,0,0,1,31,16.43M31,28.5c3.62,0,7.78,1.73,8,2.68v1.34H23V31.2c.27-1,4.43-2.7,8.05-2.7m0-14.75a5.37,5.37,0,1,0,5.36,5.36A5.36,5.36,0,0,0,31,13.75Zm0,12.07c-3.58,0-10.73,1.79-10.73,5.36v4H41.73v-4C41.73,27.61,34.58,25.82,31,25.82Z"
          />
          <path
            className="cls-2"
            d="M27.46,38.37a7.88,7.88,0,0,0,.29,1.68l-.78.77a9.43,9.43,0,0,1-.49-2.45h1m6.39,7.79a8.21,8.21,0,0,0,1.69.3v1a10,10,0,0,1-2.46-.48l.77-.78m-5.77-9.09H25.81a.65.65,0,0,0-.65.65,11,11,0,0,0,11,11,.66.66,0,0,0,.65-.65V45.83a.66.66,0,0,0-.65-.65,7.46,7.46,0,0,1-2.32-.37.64.64,0,0,0-.2,0,.68.68,0,0,0-.46.19L31.79,46.4a9.78,9.78,0,0,1-4.28-4.28l1.43-1.43A.65.65,0,0,0,29.1,40a7.31,7.31,0,0,1-.37-2.31A.65.65,0,0,0,28.08,37.07Z"
          />
        </>
      );
      break;
    case "no_patients":
      path = (
        <>
          <path
            className="cls-2"
            d="M31,16.3A2.7,2.7,0,1,1,28.3,19,2.71,2.71,0,0,1,31,16.3m0,12.16c3.65,0,7.84,1.74,8.11,2.7v1.35H22.89V31.18c.27-1,4.46-2.72,8.11-2.72M31,13.6A5.41,5.41,0,1,0,36.4,19,5.4,5.4,0,0,0,31,13.6Zm0,12.16c-3.61,0-10.81,1.81-10.81,5.4v4.06H41.81V31.16C41.81,27.57,34.61,25.76,31,25.76Z"
          />
          <polygon
            className="cls-2"
            points="35.1 37.19 31 41.28 26.9 37.19 25.29 38.79 29.39 42.89 25.29 46.99 26.9 48.6 31 44.5 35.1 48.6 36.71 46.99 32.61 42.89 36.71 38.79 35.1 37.19"
          />
        </>
      );
      break;
    case "parking":
      path = <path className="cls-2" d="M34,13.5H20.36v35h7.78V36.83H34A11.67,11.67,0,1,0,34,13.5Zm.39,15.56H28.14V21.28h6.22a3.89,3.89,0,1,1,0,7.78Z" />;
      break;
    case "radio":
      path = (
        <>
          <path
            className="cls-2"
            d="M44.33,21.83H24.83L38.6,16.27,37.47,13.5,16.4,22.08a3.31,3.31,0,0,0-2.07,3.09v20a3.34,3.34,0,0,0,3.34,3.33H44.33a3.34,3.34,0,0,0,3.34-3.33v-20A3.33,3.33,0,0,0,44.33,21.83Zm0,3.34v5H41V26.83H37.67v3.34h-20v-5Zm-26.66,20V33.5H44.33V45.17Z"
          />
          <circle className="cls-2" cx="22.65" cy="39.33" r="3.28" />
        </>
      );
      break;
    case "step":
      path = (
        <>
          <polygon
            className="cls-2"
            points="37.12 34.14 37.12 27.84 37.12 26.09 37.12 23.81 26.98 23.81 26.98 17.53 26.98 15.78 26.98 13.5 14.9 13.5 14.9 17.53 22.95 17.53 22.95 27.86 26.98 27.86 26.98 27.84 33.09 27.84 33.09 38.17 35.02 38.17 35.02 38.17 43.07 38.17 43.07 48.5 47.1 48.5 47.1 38.17 47.1 36.42 47.1 34.14 37.12 34.14"
          />
          <polygon
            className="cls-2"
            points="27.75 45.64 21.79 39.68 25.47 39.68 25.47 35.63 18.95 35.63 14.93 35.63 14.9 35.63 14.9 46.17 18.95 46.17 18.95 42.57 24.88 48.5 27.75 45.64"
          />
        </>
      );
      break;
    case "tv":
      path = (
        <>
          <path
            className="cls-2"
            d="M37.36,46.17h8A3.17,3.17,0,0,0,48.48,43l0-19.09a3.2,3.2,0,0,0-3.18-3.19H16.68A3.2,3.2,0,0,0,13.5,23.9V43a3.19,3.19,0,0,0,3.18,3.18h8M45.32,43H16.68V23.9H45.32Z"
          />
          <text className="cls-3m" transform="translate(22.78 38.56)">
            <tspan className="cls-4">T</tspan>
            <tspan x="7.31" y="0">
              V
            </tspan>
          </text>
          <polygon className="cls-2" points="31.01 21.11 30.06 22.1 24.99 16.82 25.93 15.83 31.01 21.11" />
          <polygon className="cls-2" points="31.94 22.1 30.99 21.11 36.07 15.83 37.01 16.82 31.94 22.1" />
        </>
      );
      break;
    case "water":
      path = (
        <path
          className="cls-2"
          d="M15.25,13.5l3.52,31.9a3.51,3.51,0,0,0,3.48,3.1h17.5a3.51,3.51,0,0,0,3.48-3.1l3.52-31.9ZM39.75,45l-17.5,0L20.31,27.5H41.68Zm2.33-21H19.92l-.77-7H42.83ZM31,43.25A5.25,5.25,0,0,0,36.25,38c0-3.5-5.25-9.45-5.25-9.45S25.75,34.5,25.75,38A5.25,5.25,0,0,0,31,43.25Zm0-8.91A10.13,10.13,0,0,1,32.75,38a1.75,1.75,0,0,1-3.5,0A9.91,9.91,0,0,1,31,34.34Z"
        />
      );
      break;
    case "wc":
      path = (
        <>
          <path
            className="cls-2"
            d="M23.71,34.33V26.52H22.15V20.79a2.08,2.08,0,0,1,2.08-2.08h3.12a2.09,2.09,0,0,1,2.09,2.08v5.73H27.87v7.81Zm13,0V28.08h3.12l-2.64-7.94a2.08,2.08,0,0,0-2-1.43H35.1a2.07,2.07,0,0,0-2,1.43l-2.65,7.94H33.6v6.25ZM25.79,17.67a2.09,2.09,0,1,0-2.08-2.09A2.07,2.07,0,0,0,25.79,17.67Zm9.38,0a2.09,2.09,0,1,0-2.09-2.09A2.08,2.08,0,0,0,35.17,17.67Z"
          />
          <text className="cls-3" transform="translate(21.29 45.74)">
            <tspan className="cls-4">W</tspan>
            <tspan x="11.81" y="0">
              C
            </tspan>
          </text>
        </>
      );
      break;
    case "wheel_chair":
      path = (
        <>
          <circle className="cls-1" cx="31" cy="31" r="30" />
          <circle className="cls-2" cx="31" cy="17" r="3.5" />
          <path
            className="cls-2"
            d="M43.25,32.75v-3.5a9.78,9.78,0,0,1-7.12-3.2l-2.26-2.5a3.2,3.2,0,0,0-1.07-.79s0,0,0,0h0a3.48,3.48,0,0,0-2.08-.45,3.56,3.56,0,0,0-3.17,3.62V36.25a3.5,3.5,0,0,0,3.5,3.5h8.75V48.5h3.5V38.88a3.5,3.5,0,0,0-3.5-3.5H34.5v-6A15,15,0,0,0,43.25,32.75ZM27.5,45a5.25,5.25,0,0,1-1.75-10.2V31.18A8.75,8.75,0,1,0,36.07,41.5H32.45A5.25,5.25,0,0,1,27.5,45Z"
          />
        </>
      );
      break;

    case "wifi":
      path = (
        <path
          className="cls-2"
          d="M13.5,25.87l3.18,3.18a20.27,20.27,0,0,1,28.64,0l3.18-3.18A24.76,24.76,0,0,0,13.5,25.87ZM26.23,38.6,31,43.37l4.77-4.77A6.74,6.74,0,0,0,26.23,38.6Zm-6.37-6.37,3.19,3.19a11.24,11.24,0,0,1,15.9,0l3.19-3.19A15.78,15.78,0,0,0,19.86,32.23Z"
        />
      );
      break;
  }

  return path;
}

function getExtInfoByCategoryId(id, passedData) {
  if (!id) {
    return null;
  }

  return passedData.filter((g) => g.extInfoCategoryId === id);
}

function getTooltipTitle(item) {
  let title = null;
  if (item) {
    title = item.text;
  }
  return title;
}

function getDesc(item) {
  let desc = null;
  if (item) {
    desc = item.desc;
  }
  return desc;
}

function hasCategory(id) {
  const items = data.extInfo;
  const categoryData = getExtInfoByCategoryId(id, items);

  if (!items || !categoryData) {
    return false;
  }
  const dataItems = categoryData.filter((n) => items.some((n2) => n.id === n2.id));

  if (!dataItems || (dataItems && dataItems.length == 0)) {
    return false;
  } else {
    return true;
  }
}

function renderCategory(id) {
  const items = data.extInfo;
  const categoryData = getExtInfoByCategoryId(id, items);
  const dataItems = categoryData.filter((n) => items.some((n2) => n.id === n2.id));

  if (!dataItems) {
    return null;
  }
  let iconSize = 50;

  return dataItems.map((item) => (
    <li key={item.id} className="mx-1 my-1  text-center ext-info-item" data-toggle="tooltip" data-placement="top" title={getTooltipTitle(item)}>
      {getIcon(item.image)}
      <div className="text_ext_info text-center mt-1">{getDesc(item)}</div>
    </li>
  ));
}

function renderCategoryContainer(item) {
  if (!item) {
    return null;
  }

  let render = null;
  const hasCat = hasCategory(item.id);

  if (hasCat) {
    render = (
      <div key={item.code} className="col-12 mt-2 text-center">
        <div className="text-item1 mb-1 mt-1">{item.text}</div>
        <ul key={item.code} className="list-unstyled row justify-content-center">
          {renderCategory(item.id)}
        </ul>
      </div>
    );
  }

  return render;
}

let [data, setData] = [2];
let [category, setCategory] = [2];
function ExtInfo(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [category, setCategory] = useState(null);
  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
        setCategory(result.extInfoCategory);
      }
      window.$('[data-toggle="tooltip"]').tooltip();
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <div data-aos="fade-up" data-aos-delay="100" className="row justify-content-center mx-1 mt-n2">
      {data.extInfoCategory.map((item) => renderCategoryContainer(item))}
      {/* {getIcon("wheel_chair")} */}
    </div>
  );
}

export default ExtInfo;
