import React, { useEffect, useState } from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import * as RS from "./services/RenderService";
import * as Const from "./_Const";
import * as MS from "./services/MenuService";
import * as MenuService from "./services/data/MenuItemService";
import * as GeneralSettingsService from "./services/data/GeneralSettingsService";

import Header from "./components/Header";
import Footer from "./components/Footer";
import BlogDetail from "./components/BlogDetail";
import TeamDetail from "./components/TeamDetail";
import IndexPage from "./pages/IndexPage";
import OtherPageDetail from "./components/OtherPageDetail";
import CookiePageDetail from "./components/CookiePageDetail";
import packageJson from "./../package.json";

function writeVersionTag(version) {
  const link = document.createElement("meta");
  link.setAttribute("name", "app:version");
  link.content = version;
  document.getElementsByTagName("head")[0].appendChild(link);
}

function smoothScrollToPosition() {
  const { hash } = window.location;
  if (hash !== "") {
    const id = hash.replace("#", "");
    const offset = 20;
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        let elementPosition = element.offsetTop - offset;

        if (id === Const.ID_HOME) {
          elementPosition = 0;
        }

        window.scrollTo({
          top: elementPosition, //add your necessary value
          behavior: "smooth", //Smooth transition to roll
        });
      }
    }, 450);
  }
}

async function populateData() {
  let { data: item } = await MenuService.getMenu();
  return item;
}

async function populateGeneralSettingsData() {
  let { data: item } = await GeneralSettingsService.loadData();
  return item;
}

function App() {
  const [data, setData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    AOS.init({
      disable: false,
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });

    const appVersion = packageJson.version;
    //change customer css
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = `assets/css/customers/${Const.GUID}/style.css`;

    head.appendChild(link);

    writeVersionTag(appVersion);
  }, []);

  const location = useLocation();

  //zavola se pri zmene casti url po kliknuti na link pres react-router-dom
  useEffect(() => {
    smoothScrollToPosition();
  }, [location]);

  useEffect(() => {
    async function scopedLoadData() {
      let result = await populateData();
      if (result) {
        MS.setMenuList(result);
        setData(MS.getMenuOtherPagesList());
      }

      result = await populateGeneralSettingsData();
      if (result) {
        GeneralSettingsService.setData(result);
      }
      setDataLoaded(true);
    }
    scopedLoadData();
  }, [dataLoaded]);

  if (!dataLoaded) {
    return "";
  }
  return (
    <div className="App">
      {RS.renderToastContainer()}
      <div id="domu" className="wrapper">
        {/* musim vyrenderovat alespon div abych mel pristup ke vsem funkcim ktere pouzivatji react hooks napr useState a history v menu service */}
        <MS.MenuService />
        <Header />
        <main id="main">
          <Switch>
            <Route key={Const.ID_HOME} path={MS.getLinkFromMenuId(Const.ID_HOME)} component={IndexPage} />
            <Route key={Const.ID_BLOG_DETAIL} path={MS.getLinkRouteWithDetailId(Const.ID_BLOG_DETAIL)} component={BlogDetail} />
            <Route key={Const.ID_TEAM_DETAIL} path={MS.getLinkRouteWithDetailId(Const.ID_TEAM_DETAIL)} component={TeamDetail} />

            {/* <Route key={Const.ID_OTHER_PAGES} path={MS.getLinkRouteWithDetailId(Const.ID_OTHER_PAGES)} component={OtherPageDetail} /> */}
            {/* render custom routes from backend other pages */}
            {data && data.map((item) => <Route key={item.id} exact path={`/${item.link}`} render={() => <OtherPageDetail menu={item} />} />)}
            <Route key={Const.ID_COOKIE} path={MS.getLinkFromMenuId(Const.ID_COOKIE)} component={CookiePageDetail} />

            <Route component={IndexPage} />
          </Switch>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default App;
