import React, { useEffect, useState } from "react";
import * as GalleryService from "../services/data/GalleryService";

async function populateData() {
  let { data: item } = await GalleryService.getData();
  return item.images;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      images: [
        {
          id: 0,
          title: "",
          imageSrc: "",
        },
      ],
    },
  ];

  return model;
}

function renderThumbs(item, index) {
  if (!item) {
    return null;
  }

  return (
    <li key={item.id} className="mx-1 my-2 my-sm-3 text-center" title={item.title}>
      <div className="item-zoom">
        <div
          onClick={() => {
            setCurrentImage(item);
            setIndex(index);
          }}
          className="align-content-center ig-thumbnail ig-img-hover-zoom"
        >
          <img src={item.imageSrc} />
        </div>
      </div>
    </li>
  );
}

function setNextImage() {
  if (!data || index === -1) {
    return;
  }
  let nextIndex = index + 1;
  if (nextIndex >= data.length) {
    nextIndex = 0;
  }
  const img = data[nextIndex];
  setFullScreenImage(img);
  setCurrentImage(img);
  setIndex(nextIndex);
}

function setPrevImage() {
  if (!data || index === -1) {
    return;
  }
  let nextIndex = index - 1;
  if (nextIndex < 0) {
    nextIndex = data.length - 1;
  }

  const img = data[nextIndex];
  setFullScreenImage(img);
  setCurrentImage(img);
  setIndex(nextIndex);
  //console.log(index, nextIndex, img);//
}

function renderFullScreenImage() {
  if (!fullScreenImage) {
    return null;
  }

  return (
    <div className="ig-modal-overlay">
      <div className="ig-modal-body d-flex justify-content-center align-items-center" onClick={() => setFullScreenImage(null)}>
        <img src={fullScreenImage.imageSrc} />
        {currentImage.title && <h4 className="my-2 text-center ig-image-text mb-n1">{currentImage.title}</h4>}
        <span
          className="ig-modal-left "
          onClick={(e) => {
            e.stopPropagation();
            setPrevImage();
          }}
        >
          <i class="icofont-arrow-left"></i>
        </span>
        <span
          className="ig-modal-right"
          onClick={(e) => {
            e.stopPropagation();
            setNextImage();
          }}
        >
          <i class="icofont-arrow-right"></i>
        </span>
        <span className="ig-modal-close" onClick={() => setFullScreenImage(null)}>
          <i class="icofont-ui-close"></i>
        </span>
      </div>
    </div>
  );
}

function renderImage() {
  if (!currentImage) {
    return null;
  }

  const image = new Image();
  image.src = currentImage.imageSrc;

  let cssPortrait = "";
  if (image.width < image.height) {
    cssPortrait = "portrait";
  }

  return (
    <div className="gallery container-fluid align-top">
      <div className="col-12">
        <div className="row justify-content-center" onClick={() => setFullScreenImage(currentImage)}>
          <div>
            <div className="ig-selected-image">
              <img src={currentImage.imageSrc} className={cssPortrait} />
              {currentImage.title && <h4 className="my-2 text-center ig-image-text mb-n1">{currentImage.title}</h4>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

let [data, setData] = [2];
let [currentImage, setCurrentImage] = [2];
let [fullScreenImage, setFullScreenImage] = [2];
let [index, setIndex] = [2];
function Gallery(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [currentImage, setCurrentImage] = useState(null);
  [fullScreenImage, setFullScreenImage] = useState(null);
  [index, setIndex] = useState(-1);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        const sortedResult = result.sort((a, b) => parseInt(a.order) - parseInt(b.order));
        setData(sortedResult);
        if (sortedResult.length > 0) {
          setCurrentImage(sortedResult[0]);
          setIndex(0);
        }
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  const count = data.length;

  return (
    <>
      <div data-aos="fade-up" data-aos-delay="100">
        <div className="col-12">
          <div className="row justify-content-center ">
            <div className="gallery">{renderImage()}</div>
          </div>
        </div>

        {count > 1 && (
          <div className="col-12 mt-4 text-center">
            <ul className="list-unstyled row justify-content-center">{data.map((item, index) => renderThumbs(item, index))}</ul>
          </div>
        )}
      </div>
      {fullScreenImage && <div>{renderFullScreenImage()}</div>}
    </>
  );
}

export default Gallery;
