import http from "../HttpService";

const controller = "/extinfo/fe/";

function getUrl() {
  return `${controller}${http.GUID}`;
}

export function getData() {
  return http.get(getUrl());
}
