import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { ErrorBoundary } from "react-error-boundary";
import * as Helper from "../_Helper";
import * as ContactService from "../services/data/ContactService";
import Map from "./Map";

async function populateData() {
  let { data: item } = await ContactService.getData();
  return item;
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="text-center" data-aos="fade-up" data-aos-delay="300">
      <p>Chyba při načítání mapy</p>
    </div>
  );
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      id: 0,
      latVal: 0,
      lonVal: 0,
      street: "",
      city: "",
      zip: "",
      email: [],
      phone: [],
    },
  ];

  return model;
}

function renderSocialLink(link, icon) {
  if (!link) {
    return;
  }
  return (
    <a href={link} target="_blank" className="no-underline">
      {/* {icon === "facebook" && <i className="ri-facebook-box-fill ri-4x social-link mx-3 mt-0 mx-sm-4" style={{ fontSize: 55 }}></i>}
      {icon != "facebook" && (
        <span className="pt-5">
          <i className={`icofont-${icon} icofont-3x social-link mx-3 mx-sm-4`}></i>
        </span>
      )} */}
      <i className={`ri-${icon}-fill social-link  ri-4x  mx-3 mx-sm-4`}></i>
    </a>
  );
}

let [data, setData] = [2];
function Contact() {
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, []);

  if (!data) {
    return "";
  }

  let socialNumbers = 0;
  if (data.twLink) {
    socialNumbers += 1;
  }
  if (data.fbLink) {
    socialNumbers += 1;
  }
  if (data.igLink) {
    socialNumbers += 1;
  }
  if (data.ytLink) {
    socialNumbers += 1;
  }
  let socialPostfix = "sociálních sítích";
  if (socialNumbers === 1) {
    socialPostfix = "sociální sítí";
  }

  let showMap = false;
  if (data.latVal > 0 && data.lonVal > 0) {
    showMap = true;
  }

  let phoneMailCls = "";
  let addressCls = "";
  let phoneMailAddressCount = 3;
  if (Helper.isNullOrEmptyList(data.phone, "text")) {
    phoneMailAddressCount -= 1;
  }
  if (Helper.isNullOrEmptyList(data.email, "text")) {
    phoneMailAddressCount -= 1;
  }
  switch (phoneMailAddressCount) {
    case 1:
      phoneMailCls = "col-12 mb-4 d-flex align-items-stretch";
      addressCls = phoneMailCls;
      break;
    case 2:
      phoneMailCls = "col-12 col-sm-12 col-lg-6 mb-4 d-flex align-items-stretch";
      addressCls = phoneMailCls;
      break;
    case 3:
      phoneMailCls = "col-12 col-sm-12 col-lg-6 col-xl-4 mb-4 d-flex align-items-stretch";
      addressCls = "col-12 col-sm-12 col-lg-12 col-xl-4 mb-4 d-flex align-items-stretch";
      break;
  }

  return (
    <>
      {socialNumbers > 0 && (
        <div className="row justify-content-center mb-5" data-aos="fade-up" data-aos-delay="100">
          <div className="col-12 text-center text-item1">
            <h4>Spojte se s námi na {socialPostfix}</h4>
          </div>
          <div className="row my-4">
            {renderSocialLink(data.fbLink, "facebook-box")}
            {renderSocialLink(data.igLink, "instagram")}
            {renderSocialLink(data.twLink, "twitter")}
            {renderSocialLink(data.ytLink, "youtube")}
            {/* {renderSocialLink(data.ytLink, "youtube-play")} */}
          </div>
        </div>
      )}
      {data.text && (
        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <div className="col-12 mb-4 d-flex align-items-stretch">
            <div className="contact-item w-100">
              <div className="card-body">
                <h4 className="contact-title text-center">
                  <i className="icofont-map-pins icofont-lg ml-0 mr-2"></i>
                  <span>Jak se k nám dostanete</span>
                </h4>
                <div className="mx-2 my-4">{data.text && <div>{ReactHtmlParser(data.text)}</div>}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="200">
        {!Helper.isNullOrEmptyList(data.phone, "text") && (
          <div className={phoneMailCls}>
            <div className="contact-item w-100">
              <div className="card-body">
                <h4 className="contact-title">
                  <i className="icofont-phone ml-0 mr-0"></i>
                  <span>Telefon</span>
                </h4>
                <ul className="list-unstyled  ml-0 mt-2">
                  {data.phone.map((item) => (
                    <li key={item.text} className="p-0 mt-0 ml-0">
                      <a href={Helper.getPhoneLink(item.text)}>{item.text}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {!Helper.isNullOrEmptyList(data.email, "text") && (
          <div className={phoneMailCls}>
            <div className="contact-item w-100">
              <div className="card-body">
                <h4 className="contact-title">
                  <i className="icofont-ui-email ml-0 mr-1"></i>
                  <span>Email</span>
                </h4>
                <ul className="list-unstyled ml-0 mt-2">
                  {data.email.map((item) => (
                    <li key={item.text} className="p-0 mt-0 ml-0">
                      <a href={Helper.getEmailLink(item.text)} className="break-word-to-multiline">
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className={addressCls}>
          <div className="contact-item w-100">
            <div className="card-body">
              <h4 className="contact-title">
                <i className="icofont-google-map ml-n1"></i>
                <span>Adresa</span>
              </h4>
              <ul className="list-unstyled ml-0 mt-2">
                <li key={data.street} className="p-0 mt-0 ml-0">
                  {data.street}
                </li>
                <li key={data.city} className="p-0 mt-0 ml-0">
                  {data.city}
                </li>
                <li key={data.zip} className="p-0 mt-0 ml-0">
                  {data.zip}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {showMap && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className="row" data-aos="fade-up" data-aos-delay="250">
            <div className="col-12 w-100">{<Map latVal={data.latVal} lonVal={data.lonVal} />}</div>
          </div>
        </ErrorBoundary>
      )}
    </>
  );
}

export default Contact;
