import React, { useEffect, useState } from "react";
import * as BlogService from "../services/data/BlogService";
import * as Helper from "../_Helper";
import * as Const from "../_Const";
import * as MS from "../services/MenuService";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "moment/locale/cs";
import { Link } from "react-router-dom";

async function populateData() {
  let { data: item } = await BlogService.getData();
  return item;
}

function getViewFromModel(item) {
  let model;

  model = [
    {
      title: "",
      text: "",
      date: "",
      pinned: false,
      image: "",
      imageSrc: "",
      id: 0,
    },
  ];

  return model;
}

function getDelay(value, index) {
  return value * (index + 1);
}

function getClampCss(item) {
  let css = "line-clamp-6";
  if (item) {
    let renderedImage = item.imageSrc;
    if (Helper.isNullOrEmpty(renderedImage)) {
      css = "line-clamp-15";
    }
  }

  return css;
}

function getDateTitle(date) {
  let title = date;
  if (date) {
    const localDate = moment(date).locale("cs");
    title = localDate.format("dddd D. M. YYYY");
  }
  return title;
}

function getImage(item) {
  if (item) {
    let renderedImage = item.imageSrc;
    // if (!renderedImage) {
    //   renderedImage = manNoImageSq;
    // }

    return renderedImage;
  }
}

let [data, setData] = [2];
function Blog(props) {
  const { isSectionEnabled } = props;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData();
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [isSectionEnabled]);

  if (!data) {
    return "";
  }

  return (
    <>
      <div className="row justify-content-center">
        {data.map((item, index) => (
          <div key={item.id} className="col-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center">
            <div className="blog-item d-flex flex-column" data-aos="fade-up" data-aos-delay={getDelay(100, index)}>
              {item.pinned && (
                <div className="ribbon">
                  <span>Důležité</span>
                </div>
              )}
              {!Helper.isNullOrEmpty(item.imageSrc) && (
                <div className="blog-item-img">
                  <img src={getImage(item)} alt={item.title} />
                </div>
              )}
              <div className="blog-item-info">
                <div className="date">
                  <i className="icofont-ui-calendar mr-1 date-icon-padding"></i>
                  <span>{getDateTitle(item.date)}</span>
                </div>

                <h4 className="mt-10">{item.title}</h4>
                <div className={getClampCss(item)}>{ReactHtmlParser(item.text)}</div>
              </div>
              <div className="text-center mb-3 mt-n1 mt-auto">
                <Link to={() => MS.getLinkFromMenuIdWithDetailId(Const.ID_BLOG_DETAIL, item.id)}>Více informací</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Blog;
