import React, { useEffect, useState } from "react";
import useJquery from "react-use-jquery";
import { Link } from "react-router-dom";
import DebugInfo from "./../DebugInfo";
import * as MS from "../services/MenuService";
import * as Const from "../_Const";
import * as GeneralSettingsService from "../services/data/GeneralSettingsService";
import { Helmet } from "react-helmet";
/*
function handleSectionScroll($) {
  // Navigation active state on scroll
  var nav_sections = $("section");
  var main_nav = $(".nav-menu, #mobile-nav");

  $(window).on("scroll", function () {
    var cur_pos = $(this).scrollTop() + 60;

    nav_sections.each(function () {
      var top = $(this).offset().top,
        bottom = top + $(this).outerHeight();

      if (cur_pos >= top && cur_pos <= bottom) {
        if (cur_pos <= bottom) {
          main_nav.find("li").removeClass("active");
        }
        main_nav
          .find('a[href="#' + $(this).attr("id") + '"]')
          .parent("li")
          .addClass("active");
      }
      // if (cur_pos < 300) {
      //   $(".nav-menu ul:first li:first").addClass("active");
      // }
    });
  });
}*/

function handleMobileMenu($) {
  // Mobile Navigation
  if ($(".nav-menu").length) {
    var $mobile_nav = $(".nav-menu").clone().prop({
      class: "mobile-nav d-lg-none",
    });
    $("body").append($mobile_nav);
    $("body").prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
    $("body").append('<div class="mobile-nav-overly"></div>');

    $(document).on("click", ".mobile-nav-toggle", function (e) {
      $("body").toggleClass("mobile-nav-active");
      $(".mobile-nav-toggle i").toggleClass("icofont-navigation-menu icofont-close");
      $(".mobile-nav-overly").toggle();
    });

    $(document).on("click", ".mobile-nav .drop-down > a", function (e) {
      e.preventDefault();
      $(this).next().slideToggle(300);
      $(this).parent().toggleClass("active");
    });

    $(document).click(function (e) {
      var container = $(".mobile-nav, .mobile-nav-toggle");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if ($("body").hasClass("mobile-nav-active")) {
          $("body").removeClass("mobile-nav-active");
          $(".mobile-nav-toggle i").toggleClass("icofont-navigation-menu icofont-close");
          $(".mobile-nav-overly").fadeOut();
        }
      }
    });
  } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
    $(".mobile-nav, .mobile-nav-toggle").hide();
  }
}

function getLink(item) {
  if (!item) {
    return "";
  }
  return MS.getLinkFromMenuId(item.id);
}

function getTitle(item) {
  if (!item) {
    return "";
  }
  return MS.getTitleFromMenuId(item.id);
}

function renderLink(item) {
  if (!item) {
    return "";
  }

  if (item.linkTypeId === Const.MENU_LINK_TYPE_URL) {
    return (
      <a href={item.link} target="_blank">
        {getTitle(item)}
      </a>
    );
  } else {
    return <Link to={getLink(item)}>{getTitle(item)}</Link>;
  }
}

function renderMenuItems(menuItem) {
  if (!menuItem) {
    return "";
  }
  const subMenu = MS.getMenuList(menuItem.menuId);

  return (
    <React.Fragment key={menuItem.menuId + 1000}>
      {!subMenu && <li key={menuItem.menuId}>{renderLink(menuItem)}</li>}
      {subMenu && (
        <li key={menuItem.id} className="drop-down">
          <Link className="no-underline">{getTitle(menuItem)}</Link>
          <ul>
            {subMenu.map(function (subMenuItem) {
              return <li key={subMenuItem.menuId}>{renderLink(subMenuItem)}</li>;
            })}
          </ul>
        </li>
      )}
    </React.Fragment>
  );
}

function Header() {
  const $ = useJquery();
  useEffect(() => {
    handleMobileMenu($);
  }, []);

  const mainMenu = MS.getMenuList(0);
  const data = GeneralSettingsService.getData();

  if (mainMenu != null && mainMenu.length === 0) {
    return "";
  }
  let pageTitle = "Vítáme Vás na našich stránkách";
  if (data) {
    if (data.metaTitle) {
      pageTitle = `${data.metaTitle} - hlavní stránka`;
    } else {
      pageTitle = `${data.logoTitlePrefix} ${data.logoTitle} - hlavní stránka`;
    }
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={data.metaDesc} />
        <meta name="keywords" content={data.metaKeyWord} />
      </Helmet>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <div className="logo mr-auto">
            <h1 className="text-light">
              <Link to={MS.getLinkFromMenuId(Const.ID_HOME)}>
                <span>
                  {data && data.logoIcon && <i className={`${data.logoIcon} logo-icon mr-1`}></i>}
                  {data && data.logoTitlePrefix && <span className="logo-prefix mr-1">{data.logoTitlePrefix}</span>}
                  {data && data.logoTitle && <span className="logo-title">{data.logoTitle}</span>}
                </span>
                <span className="debug-info ml-2">
                  <DebugInfo />
                </span>
              </Link>
            </h1>
          </div>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              {mainMenu &&
                mainMenu.map(function (menuItem) {
                  return <React.Fragment key={`menu-${menuItem.id}`}>{renderMenuItems(menuItem)}</React.Fragment>;
                })}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
