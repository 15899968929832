import React, { useEffect, useState } from "react";
import * as OtherPageService from "../services/data/OtherPageService";
import * as Helper from "../_Helper";
import ReactHtmlParser from "react-html-parser";
import "moment/locale/cs";
import Breadcrumb from "./Breadcrumb";
import * as GeneralSettingsService from "../services/data/GeneralSettingsService";
import { Helmet } from "react-helmet";
import ScrollToTop from "./../ScrollToTop";
import GoBack from "./../GoBack";

async function populateData(id) {
  let { data: item } = await OtherPageService.getItem(id);
  return item;
}

function getViewFromModel(item) {
  let model;

  model = {
    title: "",
    text: "",
    date: "",
    pinned: false,
    image: "",
    imageSrc: "",
    id: 0,
  };

  return model;
}

function getImage(item) {
  if (item) {
    let renderedImage = item.imageSrc;

    return renderedImage;
  }
}

let [data, setData] = [2];
function OtherPageDetail(props) {
  const { menu } = props;
  //console.log(props, menu);
  const paramId = menu.linkIdOther;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  //console.log(paramId);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(paramId);
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, [paramId]);

  if (!data) {
    return "";
  }

  const hasImage = !Helper.isNullOrEmpty(data.imageSrc);
  const columns = hasImage ? "col-md-6" : "col-md-12";

  const settingsData = GeneralSettingsService.getData();
  let pageTitle = "Detail novinek v ordinaci";
  if (settingsData) {
    if (settingsData.metaTitle) {
      pageTitle = `${settingsData.metaTitle} - ${data.title}`;
    } else {
      pageTitle = `${settingsData.logoTitlePrefix} ${settingsData.logoTitle} - ${data.title}`;
    }
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumb title={data.title} section={data.title} />
      <ScrollToTop />
      <section id="otherPageDetail" className="blog-detail">
        <div className="container">
          <div className="row blog-item" data-aos="fade-up">
            <div className="col-12">
              <div className="blog-item-info">
                <h4>{data.title}</h4>
                {hasImage && (
                  <div className="d-none d-lg-block float-right blog-float-image">
                    <img src={getImage(data)} alt={data.title} className="img-fluid" />
                  </div>
                )}
                <div className="mt-4">{ReactHtmlParser(data.text)}</div>
                {hasImage && (
                  <div className="d-block d-lg-none blog my-5 text-center">
                    <img src={getImage(data)} alt={data.title} className="img-fluid" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col mt-2" data-aos="fade-up">
              <GoBack />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OtherPageDetail;
