import { useHistory } from "react-router-dom";

function GoBack(params) {
  const history = useHistory();
  return (
    <button onClick={history.goBack} className="btn btn-action btn-go-back">
      <span>
        <i className="icofont-arrow-left mr-2"></i>Zpět
      </span>
    </button>
  );
}

export default GoBack;
