import React, { useEffect, useState } from "react";
import * as BlogService from "../services/data/BlogService";
import * as Helper from "../_Helper";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "moment/locale/cs";
import Breadcrumb from "./Breadcrumb";
import * as GeneralSettingsService from "../services/data/GeneralSettingsService";
import { Helmet } from "react-helmet";
import ScrollToTop from "./../ScrollToTop";
import GoBack from "./../GoBack";

async function populateData(id) {
  let { data: item } = await BlogService.getItem(id);
  return item;
}

function getViewFromModel(item) {
  let model;

  model = {
    title: "",
    text: "",
    date: "",
    pinned: false,
    image: "",
    imageSrc: "",
    id: 0,
  };

  return model;
}

function getDelay(value, index) {
  return value * (index + 1);
}

function getClampCss(item) {
  let css = "line-clamp-6";
  if (item) {
    let renderedImage = item.imageSrc;
    if (Helper.isNullOrEmpty(renderedImage)) {
      css = "line-clamp-15";
    }
  }

  return css;
}

function getDateTitle(date) {
  let title = date;
  if (date) {
    const localDate = moment(date).locale("cs");
    title = localDate.format("dddd D. M. YYYY");
  }
  return title;
}

function getImage(item) {
  if (item) {
    let renderedImage = item.imageSrc;

    return renderedImage;
  }
}

let [data, setData] = [2];
function BlogDetail(props) {
  const paramId = props.match.params.id;
  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(paramId);
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, []);

  if (!data) {
    return "";
  }

  const hasImage = !Helper.isNullOrEmpty(data.imageSrc);
  const columns = hasImage ? "col-md-6" : "col-md-12";

  const settingsData = GeneralSettingsService.getData();
  let pageTitle = "Detail novinek v ordinaci";
  if (settingsData) {
    if (settingsData.metaTitle) {
      pageTitle = `${settingsData.metaTitle} - ${data.title}`;
    } else {
      pageTitle = `${settingsData.logoTitlePrefix} ${settingsData.logoTitle} - ${data.title}`;
    }
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumb title={data.title} section="Detail novinky" />
      <ScrollToTop />
      <section id="blogDetail" className="blog-detail">
        <div className="container">
          <div className="row blog-item" data-aos="fade-up">
            <div className={columns}>
              {!hasImage && data.pinned && (
                <div className="ribbon">
                  <span>Důležité</span>
                </div>
              )}
              <div className="blog-item-info">
                <h4>{data.title}</h4>
                <div className="date mt-2">
                  <i className="icofont-ui-calendar mr-1 date-icon-padding"></i>
                  <span>{getDateTitle(data.date)}</span>
                </div>

                <div className="mt-4">{ReactHtmlParser(data.text)}</div>
              </div>
            </div>

            {hasImage && (
              <div className="col-md-6">
                <div className="blog-item-img">
                  {data.pinned && (
                    <div className="ribbon">
                      <span>Důležité</span>
                    </div>
                  )}
                  <img src={getImage(data)} alt={data.title} />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col mt-2" data-aos="fade-up">
              <GoBack />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
