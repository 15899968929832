import React, { useEffect } from "react";

function Map(props) {
  useEffect(() => {
    function scopedLoadData() {
      let center = window.SMap.Coords.fromWGS84(props.lonVal, props.latVal);
      let m = new window.SMap(window.JAK.gel("m"), center, 18);
      m.addDefaultLayer(window.SMap.DEF_BASE).enable();

      let sync = new window.SMap.Control.Sync();
      m.addControl(sync);

      let scale = new window.SMap.Control.Scale();
      m.addControl(scale);

      var labels = {
        2: "Svět",
        5: "Státy",
        8: "Kraje",
        11: "Města",
        14: "Obce",
        17: "Ulice",
        20: "Domy",
      };
      let zoom = new window.SMap.Control.Zoom(labels, { titles: ["Přiblížit", "Oddálit"] });
      m.addControl(zoom);

      let layer = new window.SMap.Layer.Marker();
      m.addLayer(layer);
      layer.enable();

      var options = {};
      var marker = new window.SMap.Marker(center, "myMarker", options);
      layer.addMarker(marker);
    }
    scopedLoadData();
  });

  const mystyle = {
    width: "auto",
    height: "600px",
  };
  return <div id="m" style={mystyle}></div>;
}

export default Map;
