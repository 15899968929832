import React from "react";
import moment from "moment";

import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import ReactLoading from "react-loading";

let loadingToastLastCallingTime;

export function getDateToString(date = null) {
  return date ? moment.unix(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
}

export function stripHtml(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function textShortener(text, len) {
  if (text && text.length > len) {
    text = text.substring(0, len) + "... (zkrácený text)";
  }
  return text;
}

export function renderToastContainer() {
  return <ToastContainer />;
}

export function toastSuccess(text) {
  toast.success(text, {
    position: "top-right",
    hideProgressBar: true,
    transition: Slide,
    autoClose: 1500,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastInfo(text) {
  toast.info(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    transition: Slide,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastError(text) {
  toast.error(text, {
    position: "top-right",
    autoClose: 12000,
    hideProgressBar: true,
    transition: Slide,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastSave() {
  toastSuccess("Data byla úspěšně uložena");
}

export function toastDelete() {
  toastSuccess("Položka byla úspěšně vymazána");
}

export function toastDeleteAll() {
  toastSuccess("Data byla úspěšně vymazána");
}

/*
fce se stara o nastaveni loading dialogu v pripade ze akce 
trva vice nez 250ms
 */
export function setLoading(isLoading = true) {
  loadingToastLastCallingTime = Date.now();

  setTimeout(() => {
    const millis = Date.now() - loadingToastLastCallingTime;

    //za posledni X ms nebylo volana tato metoda - tudiz vykonej kod
    if (millis >= 250) {
      if (!isLoading) {
        closeAllToast("customId");
      } else {
        toast(renderLoading, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          transition: Slide,
          closeOnClick: true,
          closeButton: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          toastId: "customId",
        });
      }
    }
  }, 250);
}

export function closeAllToast(id) {
  toast.dismiss(id);
}

export function renderLoading(isLoading) {
  const color = "#e91e63";
  const type = "spin";
  const w = 35;
  const h = 35;

  if (!isLoading) {
    return null;
  }

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <ReactLoading type={type} color={color} height={h} width={w} />
    </div>
  );
}
