import React, { useEffect, useState } from "react";
import Blog from "../components/Blog";
import Team from "../components/Team";
import Gallery from "../components/Gallery";
import UrgentNotice from "../components/UrgentNotice";
import OpeningHour from "../components/OpeningHour";
import Insurances from "../components/Insurances";
import ExtInfo from "../components/ExtInfo";
import PriceList from "../components/PriceList";
import Faq from "../components/Faq";
import About from "../components/About";
import OurService from "../components/OurService";
import Hero from "../components/Hero";
import Files from "../components/Files";
import Contact from "../components/Contact";
import * as Const from "../_Const";
import Section from "../components/Section";
import * as MS from "./../services/MenuService";

function getSection(item) {
  if (!item || (item && !item.id)) {
    return;
  }

  let section = null;

  switch (item.id) {
    case Const.ID_ABOUT:
      section = (
        <Section ke sectionId={Const.ID_ABOUT} menuSectionId={item.sectionLink} cssClass="about">
          <About />
        </Section>
      );
      break;
    case Const.ID_ORDINATION_HOUR:
      section = (
        <Section sectionId={Const.ID_ORDINATION_HOUR} menuSectionId={item.sectionLink}>
          <OpeningHour />
        </Section>
      );
      break;
    case Const.ID_SERVICES:
      section = (
        <Section sectionId={Const.ID_SERVICES} menuSectionId={item.sectionLink} cssClass="services">
          <OurService />
        </Section>
      );
      break;

    case Const.ID_PRICING:
      section = (
        <Section sectionId={Const.ID_PRICING} menuSectionId={item.sectionLink} cssClass="pricing">
          <PriceList />
        </Section>
      );
      break;
    case Const.ID_BLOG:
      section = (
        <Section sectionId={Const.ID_BLOG} menuSectionId={item.sectionLink} cssClass="blog">
          <Blog />
        </Section>
      );
      break;
    case Const.ID_TEAM:
      section = (
        <Section sectionId={Const.ID_TEAM} menuSectionId={item.sectionLink} cssClass="team">
          <Team />
        </Section>
      );
      break;
    case Const.ID_EXT_INFO:
      section = (
        <Section sectionId={Const.ID_EXT_INFO} menuSectionId={item.sectionLink}>
          <ExtInfo />
        </Section>
      );
      break;
    case Const.ID_INSURANCES:
      section = (
        <Section sectionId={Const.ID_INSURANCES} menuSectionId={item.sectionLink}>
          <Insurances />
        </Section>
      );
      break;
    case Const.ID_FAQ:
      section = (
        <Section sectionId={Const.ID_FAQ} menuSectionId={item.sectionLink} cssClass="faq">
          <Faq />
        </Section>
      );
      break;
    case Const.ID_GALLERY:
      section = (
        <Section sectionId={Const.ID_GALLERY} menuSectionId={item.sectionLink}>
          <Gallery />
        </Section>
      );
      break;
    case Const.ID_FILES:
      section = (
        <Section sectionId={Const.ID_FILES} menuSectionId={item.sectionLink}>
          <Files />
        </Section>
      );
      break;
    case Const.ID_CONTACT:
      section = (
        <Section sectionId={Const.ID_CONTACT} menuSectionId={item.sectionLink} cssClass="contact">
          <Contact />
        </Section>
      );
      break;

    default:
      break;
  }
  return section;
}

function IndexPage() {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function scopedLoadData() {
      setData(MS.getMenuListAll());
      //console.log("indepage data loaded", data);
    }
    scopedLoadData();
  }, []);

  //console.log("indepage render", data);

  return (
    <>
      <Hero />
      <UrgentNotice />

      {data &&
        data.map((item) => {
          return <React.Fragment key={`section-${item.id}`}>{getSection(item)}</React.Fragment>;
        })}
    </>
  );
}

export default IndexPage;
