export function isNullOrEmpty(text) {
  let isNullOrEmpty = false;

  if (text === "null" || text === "") {
    isNullOrEmpty = true;
  }

  if (typeof text !== "undefined" && text) {
    isNullOrEmpty = false;
  } else {
    isNullOrEmpty = true;
  }

  return isNullOrEmpty;
}

export function isNullOrEmptyList(list, item) {
  let isEmpty = true;

  if (list) {
    list.forEach(function (arrayItem) {
      if (isEmpty) {
        if (!isNullOrEmpty(arrayItem[item])) {
          isEmpty = false;
        }
      }
    });
  }

  return isEmpty;
}

export function getPhoneLink(phone) {
  if (!phone) {
    return "";
  }

  return `tel:${phone.replace(/\s/g, "")}`;
}

export function getEmailLink(email) {
  if (!email) {
    return "";
  }

  return `mailto:${email.replace(/\s/g, "")}`;
}

export function stripHtml(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function textShortener(text, len) {
  if (text && text.length > len) {
    text = text.substring(0, len) + "... (zkrácený text)";
  }
  return text;
}

export function needGoToDetail(text, len) {
  let needGoToDetail = false;
  if (text && text.length > len) {
    needGoToDetail = true;
  }
  return needGoToDetail;
}
