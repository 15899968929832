import http from "../HttpService";

const controller = "/ourservice/fe/";

function getUrl() {
  return `${controller}${http.GUID}`;
}

export function getItem() {
  return http.get(getUrl());
}
