import http from "../HttpService";

const controller = "/section/fe/";

function getUrlItem(sectionCode) {
  return `${controller}${http.GUID}/${sectionCode}`;
}

function getUrlItemMenu() {
  return `${controller}menu/${http.GUID}/`;
}

export function getItem(sectionCode) {
  return http.get(getUrlItem(sectionCode));
}

export function getMenu() {
  return http.get(getUrlItemMenu());
}
